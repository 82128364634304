.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* background-color: #f0f2f5; */
}

.form-container {
  width: 400px;
  padding: 30px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(249, 247, 247, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.logo {
  width: 100px;
  height: 80px;
  font-size: 48px;
  color: #1877f2;
}

.logo-text {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: white;
  /* color: #000; */
}

.form {
  display: flex;
  flex-direction: column;
  gap: "10px";
}

.input {
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #dadde1;
  border-radius: 5px;
  font-size: 14px;
}
.password-input {
  width: 100%; /* or adjust the width as needed */
  margin-bottom: 16px; /* to match your .input class margin */
  margin-top: 10px;
}

.button {
  padding: 10px 16px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1877f2;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 14px;
}

.switch-button {
  margin-left: 5px;
  border: none;
  background-color: transparent;
  color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.switch-button:hover {
  text-decoration: underline;
}
